const map = {
  "app.imbot.com.br": { displayName: "imbot", urlLogo: "", mainColor: "" },
  "stg.app.imbot.com.br": { displayName: "imbot", urlLogo: "", mainColor: "" },
  "bot.ibasi.com.br": { displayName: "ibasibot", urlLogo: "", mainColor: "" },
  "botstaging.ibasi.com.br": {
    displayName: "ibasibot",
    urlLogo: "",
    mainColor: ""
  }
} as Record<
  string,
  { displayName: string; urlLogo: string; mainColor: string }
>;

const plataform = window.location.href
  .replaceAll(/https?:\/\//gu, "")
  .replaceAll(/\/.*/gu, "");

export default map[plataform] ?? {
  displayName: "imbot",
  urlLogo: "",
  mainColor: ""
};

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import sidebar from "../sidebar/sidebar";
import { Dropdown } from "primereact/dropdown";

export const PlayVideo = css`
  background-color: #ff0025;
  min-width: 1.1875rem;
  min-height: 1.1875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledButton = styled.button`
  height: 46px;
  width: 160px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.25px;
  background: #046af3;
  border: 1px solid #046af3;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: #fff;
  background-color: rgba(4, 106, 243, 1);
  position: relative;
  z-index: 0;
  :hover {
    cursor: pointer;
    background-color: #024aae;
  }
`;
export const Container = styled.div`
  height: 79px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  .p-inputtext {
    font-size: 14px !important;
    color: #046af3 !important;
    padding: 0.285rem 1rem;
  }

  .p-dropdown .p-dropdown-trigger {
    color: #046af3 !important;
  }
`;
interface iContainerBegin {
  sidebarHidden: boolean;
}
export const ContainerBegin = styled.div<iContainerBegin>`
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.sidebarHidden ? "111px" : "240px")};
  padding-left: 0;
  transition: padding 250ms ease-out;
  height: 79px;
`;
export const ContainerEnd = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  .buttons {
    display: flex;
    // width: 100%;
    gap: 1rem;
  }
`;
export const ButtonBack = styled.div`
  margin-left: 1rem;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  transition: all 0.2s;
  :hover {
    background-color: #024aae;
    cursor: pointer;
  }
`;
interface iContainerLogo {
  expanded: boolean;
}
export const ContainerLogo = styled.div<iContainerLogo>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: ${(props) => (props.expanded ? "240px" : "80px")};
  gap: 10px;
  border-top-right-radius: 20px;

  transition: ${(props) =>
    props.expanded ? " width 250ms ease-out" : "width 250ms linear"};
  :hover {
    cursor: pointer;
  }
`;
export const Img = styled.img`
  :hover {
    cursor: pointer;
  }
`;
export const ImageArrowRight = styled.img`
  position: absolute;
  height: 12px;
  right: 0.5rem;
  top: 50%;
`;

export const IconButton = styled.div`
  display: flex;
  background-color: #f7f7f7;
  color: #046af3;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 6px;
`;

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 46px;
  background-color: #d8e9f6;
  color: #046af3;
  border: none;
  text-emphasis-color: #fff;
  .p-dropdown-label {
    color: #fff;
    padding-right: unset !important;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #fff !important;
  }
  .p-inputtext {
    color: #046af3 !important;
    font-size: 14px !important;
    padding-right: 0;
  }
  .p-dropdown-trigger {
    color: #046af3 !important;
  }
  .p-dropdown .p-dropdown-trigger {
    color: #fff !important;
  }
  .p-dropdown-trigger-icon .p-clickable .pi .pi-chevron-down {
    color: #fff !important;
  }
  > i {
    color: #fff !important;
  }
  :hover {
    cursor: pointer;
  }
  .custom-icon.pi.pi-check {
    color: #fff !important;
  }
`;

export const ContainerDropdown = styled.div`
  display: flex;
  align-items: center;
  background-color: #d8e9f6;
  border-radius: 8px;
  border: 1px solid #d8e9f6;
  padding-left: 0.7rem;
  height: 46px;
  :hover {
    cursor: pointer;
  }
`;

export const ContainerLeftModal = styled.div`
  position: absolute;
  background-color: #fff;
  top: -3rem;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110%;
  padding: 1rem;
  padding-left: 2rem;
  padding-top: 3rem;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .title {
    font-size: 16px;
    text-align: center;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0.5rem;
  }
`;
export const ContainerRightModal = styled.div`
  width: 50%;
  position: relative;
  height: 100%;
  background-color: #ff0025;
  color: white;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1rem;

  .imageTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    font-size: 12px;
  }

  .link {
    display: flex;
    align-items: center;
    width: 100%;
    // cursor: pointer;
  }
`;

export const LeftHeaderWhiter = styled.div`
  width: 50%;
  position: absolute;
  height: 100%;
  top: 0px;
  border-radius: 20px;
  background-color: white;
  border-top-right-radius: 3rem;
  border-top-left-radius: 1rem;
`;

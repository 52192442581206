import { useAppDispatch } from "../redux/hooks";
import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContextType, useAuth } from "../auth/auth-provider";
import {
  Button,
  InputText,
  Password,
  PoliticaPrivacidadeConfirmar
} from "../components";
import datatable from "../components/datatable";
import { v } from "../util";
import {
  botRed,
  background,
  dialogosInteligentes,
  successIcon
} from "./../assets";
import { useEffect, useState } from "react";
import Plataform from "../services/Plataform";

interface UserLogin {
  id: number;
  name: string;
  isFirstAccess: boolean;
}

const schema = v.object({
  name: v.string().superRefine(v.nonempty()).superRefine(v.email()),
  password: v.string().superRefine(v.nonempty())
});

export default () => {
  const { email, invite } = useParams();
  const dispatch = useAppDispatch();
  const [loginError, setloginError] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: "",
      password: ""
    },
    resolver: zodResolver(schema)
  });
  const [isMobile, setIsMobile] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { user, hasAnyPermission, logout } = useAuth() as AuthContextType;

  const from =
    (location.state as { from: { pathname: string } })?.from?.pathname || "/";

  const name = watch("name");
  const password = watch("password");

  useEffect(() => {
    if (name.length || password.length) {
      setloginError("");
    }
  }, [name, password]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 600);
    }
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      css={css`
        height: 100vh;
        width: 100vw;
        flex-direction: row;
        display: flex;

        @media (max-width: 950px) {
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          background-image: url(${background});
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          <img
            src={dialogosInteligentes}
            css={css`
              @media (max-width: 950px) {
                max-width: 100%;
                width: auto;
                height: auto;
              }
            `}
          />
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{Plataform.displayName}</p>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;

          @media (max-width: 950px) {
            margin-top: 0.5rem;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
            gap: 20px;

            @media (max-width: 500px) {
              width: 90%;
            }
          `}
        >
          {invite ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                width: 300px;
                height: 50px;
                background-color: #d5f6e4e5;
                border-width: 3px;
                border-color: #2ed47a;
                border-radius: 0.5rem;
                gap: 10px;
              `}
            >
              <img src={successIcon} />
              <span
                css={css`
                  color: #1dc068;
                `}
              >
                Cadastro efetuado com sucesso!
              </span>
            </div>
          ) : email ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                width: 300px;
                height: 50px;
                background-color: #d5f6e4e5;
                border-width: 3px;
                border-color: #2ed47a;
                border-radius: 0.5rem;
                gap: 10px;
              `}
            >
              <img src={successIcon} />
              <span
                css={css`
                  color: #1dc068;
                `}
              >
                Senha atualizada com sucesso
              </span>
            </div>
          ) : null}
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
            `}
          >
            <img src={Plataform.urlLogo ? Plataform.urlLogo : botRed} />
            <div>
              <span>Olá, somos o </span>
              <span
                css={css`
                  font-weight: 600;
                `}
              >
                {Plataform.displayName}
              </span>
              👋
              <div
                css={css`
                  font-weight: 600;
                `}
              >
                Entre e tenha uma experiência incrível.
              </div>
            </div>
          </div>

          <div
            css={css`
              margin-top: 25px;
              width: 100%;
            `}
          >
            <form
              onSubmit={handleSubmit(async (data) => {
                setLoading(true);
                const retorno = await login(data.name, data.password);
                if (retorno?.user) {
                  setLoading(false);
                  if (retorno?.user?.isFirstAccess) {
                    navigate(
                      `/avatar/${retorno?.user?.id}/${retorno?.user?.name}`,
                      { replace: true }
                    );
                  }
                  if (isMobile) {
                    navigate("/rooms", { replace: true });
                  } else if (
                    retorno?.user?.isAttendant &&
                    hasAnyPermission("omnichannel_view")
                  ) {
                    navigate("/omnichannel", { replace: true });
                  } else {
                    navigate("/home", { replace: true });
                  }
                } else {
                  setloginError(retorno?.message);
                  setLoading(false);
                  navigate("/login");
                }
              })}
            >
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  gap: 25px;
                `}
              >
                <div>
                  <div className="p-float-label">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => <InputText {...field} id="name" />}
                    />
                    <label htmlFor="name">E-mail</label>
                  </div>
                  {errors.name?.message ? (
                    <div
                      css={css`
                        margin-top: 5px;
                        font-size: 12px;
                        color: #ff4d4f;
                      `}
                    >
                      {errors.name?.message}
                    </div>
                  ) : null}
                </div>

                <div>
                  <div className="p-float-label">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <Password {...field} id="password" />
                      )}
                    />
                    <label htmlFor="password">Senha</label>
                  </div>
                  {errors.password?.message ? (
                    <div
                      css={css`
                        margin-top: 5px;
                        font-size: 12px;
                        color: #ff4d4f;
                      `}
                    >
                      {errors.password?.message}
                    </div>
                  ) : null}
                </div>
                {loginError.length ? (
                  <div
                    css={css`
                      margin-top: 5px;
                      font-size: 12px;
                      color: #ff4d4f;
                    `}
                  >
                    {loginError}
                  </div>
                ) : null}
                <Button
                  loading={loading}
                  css={css`
                    width: 100%;
                  `}
                  label="Entrar"
                  disabled={
                    name.trim().length === 0 || password.trim().length === 0
                  }
                />
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 950px) {
                      margin-bottom: 0.5rem;
                    }
                  `}
                >
                  <Link to="/forgot">Esqueceu sua senha?</Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
